<template>
	<div class="app-view">
		<van-nav-bar title="拉取订单数据" left-text="返回" left-arrow @click-left="$routerGo(-1)" />
		<van-field readonly clickable name="datetimePicker" :value="start_time" label="开始时间" placeholder="点击选择时间" @click="showPicker" />
		<van-field readonly clickable name="datetimePicker" :value="end_time" label="结束时间" placeholder="请选择开始时间" />
		<van-field name="radio" label="订单类型">
			<template #input>
				<van-radio-group v-model="order_scene" direction="horizontal">
					<van-radio name="1">常规订单</van-radio>
					<van-radio name="2">渠道订单</van-radio>
				</van-radio-group>
			</template>
		</van-field>
		<van-field readonly clickable name="picker" :value="columnsArr[query_type-1]" label="查询类型" placeholder="点击选择查询时间类型" @click="showTypePicker = true" />
        <div style="margin: 16px;">
            <van-button round block type="info" @click="sumitData">提交</van-button>
        </div>
		<van-popup v-model="showTypePicker" position="bottom">
			<van-picker show-toolbar :columns="columns" @confirm="onConfirm" @cancel="showTypePicker = false" />
		</van-popup>
		<van-popup v-model="showDatePicker" round position="bottom">
			<van-datetime-picker v-model="currentDate" type="datetime" title="选择完整时间" @confirm="changeDate" />
		</van-popup>
	</div>
</template>
<script>
export default {
	data() {
		return {
			start_time: '',
			end_time: '',
			// 1：按照订单淘客创建时间查询，2:按照订单淘客付款时间查询，3:按照订单淘客结算时间查询，4:按照订单更新时间查询；
			query_type: '1',
			// 1:常规订单，2:渠道订单，3:会员运营订单，默认为1
			order_scene: '1',
			currentDate: '',
            columnsArr: ['创建时间', '付款时间', '结算时间', '更新时间'],
            columns: [{ text: '创建时间', id: '1' }, {text: '付款时间', id: '2'}, { text: '结算时间', id: '3'}, { text: '更新时间', id: '4'}],
            showTypePicker: false,
			showDatePicker: false
		}
	},
	created() {
		this.currentDate = new Date()
	},
	methods: {
		showPicker() {
			this.showDatePicker = true
		},
        onConfirm(value){
            console.log(value)
            this.query_type = value.id
            this.showTypePicker = false;
        },
		changeDate(value) {
			this.showDatePicker = false
			this.currentDate = this.$moment(value).format('YYYY-MM-DD HH:mm:ss')
			this.start_time = this.currentDate
			this.end_time = this.$moment(value).add('20', 'm').format('YYYY-MM-DD HH:mm:ss')
		},
        sumitData(){
            if(this.start_time=='' || this.query_type==''){
                this.$toast('请填写完整')
                return false;
            }
            this.$ask.post('/getOrderListByTime', { start_time: this.start_time, end_time: this.end_time, query_type: this.query_type, order_scene: this.order_scene }).catch(this.$toastError)
        }
	}
}
</script>
